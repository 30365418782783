<template>
  <td>
    <slot>
      {{ props.column.value }}
    </slot>
  </td>
</template>

<script setup lang="ts">
import type { BodyCellProps } from './UiBaseTable.types'

const props = withDefaults(defineProps<BodyCellProps>(), {})
</script>
